<template>
  <div v-if="!pdfUrl" ref="pdfContainer" class="print-menu-container">
    <!-- Pagina 1 -->
    <div ref="page1Ref" data-rotate="false" class="page">
      <div class="menu-section">
        <div class="menu-col">
          <div class="menu-col-group">
            <!-- Secondi -->
            <div
              v-if="secondiObj"
              class="section-title"
              style="margin-top: 22px"
            >
              {{ useMenuUtils().extractNodeName(secondiObj, 'carta-category') }}
            </div>
            <div class="menu-items-list">
              <div
                class="menu-item"
                v-for="product in secondiObj.products
                  ? secondiObj.products
                  : []"
                :key="product.idprodotto"
              >
                <p>
                  <span class="item-name">{{
                    useMenuUtils().extractNodeName(product, 'product')
                  }}</span>
                  <span
                    v-if="
                      useMenuUtils().extractNodeAllergies(
                        product,
                        menuProducts?.prodotti,
                      )
                    "
                    class="item-allergy"
                    >{{
                      useMenuUtils().extractNodeAllergies(
                        product,
                        menuProducts?.prodotti,
                      )
                    }}</span
                  >
                </p>
                <p class="item-price">{{ product.prezzo }}</p>
              </div>
            </div>

            <div class="" style="margin-top: 26px">
              <div class="menu-item">
                <p>
                  <span class="item-name">{{ $t('menu.print.coperto') }}</span>
                </p>
                <p class="item-price">4</p>
              </div>
            </div>
          </div>

          <div class="menu-col-group">
            <div class="logos-list">
              <img src="/assets/logo-michelin.png" alt="Logo Michelin" />
              <img src="/assets/logo-osti.png" alt="Logo Osti in Orto" />
            </div>

            <div class="allergy-legenda">
              <p class="allergy-legenda-title">
                {{ $t('menu.print.legenda') }}
              </p>
              <div class="allergy-list-wrap">
                <ol class="allergy-list">
                  <li>{{ $t('menu.print.allergeni.cereali') }}</li>
                  <li>{{ $t('menu.print.allergeni.crostacei') }}</li>
                  <li>{{ $t('menu.print.allergeni.uova') }}</li>
                  <li>{{ $t('menu.print.allergeni.pesce') }}</li>
                  <li>{{ $t('menu.print.allergeni.arachidi') }}</li>
                  <li>{{ $t('menu.print.allergeni.soia') }}</li>
                  <li>{{ $t('menu.print.allergeni.latte') }}</li>
                  <li>{{ $t('menu.print.allergeni.frutta') }}</li>
                  <li>{{ $t('menu.print.allergeni.sedano') }}</li>
                  <li>{{ $t('menu.print.allergeni.senape') }}</li>
                  <li>{{ $t('menu.print.allergeni.sesamo') }}</li>
                  <li>{{ $t('menu.print.allergeni.anidride') }}</li>
                  <li>{{ $t('menu.print.allergeni.lupini') }}</li>
                  <li>{{ $t('menu.print.allergeni.molluschi') }}</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div class="menu-col">
          <div class="menu-col-group">
            <!-- Main Logo -->
            <div class="main-logo-container">
              <img src="/assets/logo-menu.svg" alt="Logo Ristorante Da Guido" />
            </div>

            <!-- Menu Degustazione -->
            <div class="section-title">
              {{ useMenuUtils().extractNodeName(degustaObj, 'carta-category') }}
            </div>
            <div class="menu-items-list --concise">
              <div
                class="menu-item"
                v-for="product in degustaObj.products
                  ? degustaObj.products
                  : []"
                :key="product.idprodotto"
              >
                <p>
                  <span class="item-name">{{
                    useMenuUtils().extractNodeName(product, 'product')
                  }}</span>
                  <span
                    v-if="
                      useMenuUtils().extractNodeAllergies(
                        product,
                        menuProducts?.prodotti,
                      )
                    "
                    class="item-allergy"
                    >{{
                      useMenuUtils().extractNodeAllergies(
                        product,
                        menuProducts?.prodotti,
                      )
                    }}</span
                  >
                </p>
              </div>
            </div>
            <div class="" style="margin-top: 24px">
              <div class="menu-item">
                <p>
                  <span class="item-name --lighter"
                    >{{ $t('menu.print.fixed-price-menu') }}
                  </span>
                </p>
                <p class="item-price">€{{ degustaObj.prezzo }}</p>
              </div>
            </div>
          </div>

          <div class="menu-col-group">
            <!-- Menu Vegetariano -->
            <div class="section-title">
              {{ useMenuUtils().extractNodeName(vegObj, 'carta-category') }}
            </div>
            <div class="menu-items-list">
              <div
                class="menu-item"
                v-for="product in vegObj.products ? vegObj.products : []"
                :key="product.idprodotto"
              >
                <p>
                  <span class="item-name">{{
                    useMenuUtils().extractNodeName(product, 'product')
                  }}</span>
                  <span
                    v-if="
                      useMenuUtils().extractNodeAllergies(
                        product,
                        menuProducts?.prodotti,
                      )
                    "
                    class="item-allergy"
                    >{{
                      useMenuUtils().extractNodeAllergies(
                        product,
                        menuProducts?.prodotti,
                      )
                    }}</span
                  >
                </p>
                <p class="item-price">{{ product.prezzo }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Pagina 2 -->
    <div ref="page2Ref" data-rotate="true" class="page">
      <div class="menu-section">
        <div class="menu-col">
          <div class="menu-col-group">
            <!-- Antipasti -->
            <div v-if="secondiObj" class="section-title">
              {{
                useMenuUtils().extractNodeName(antipastiObj, 'carta-category')
              }}
            </div>
            <div class="menu-items-list">
              <div
                class="menu-item"
                v-for="product in antipastiObj.products
                  ? antipastiObj.products
                  : []"
                :key="product.idprodotto"
              >
                <p>
                  <span class="item-name">{{
                    useMenuUtils().extractNodeName(product, 'product')
                  }}</span>
                  <span
                    v-if="
                      useMenuUtils().extractNodeAllergies(
                        product,
                        menuProducts?.prodotti,
                      )
                    "
                    class="item-allergy"
                    >{{
                      useMenuUtils().extractNodeAllergies(
                        product,
                        menuProducts?.prodotti,
                      )
                    }}</span
                  >
                </p>
                <p class="item-price">{{ product.prezzo }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="menu-col">
          <div class="menu-col-group">
            <!-- Primi -->
            <div v-if="primiObj" class="section-title">
              {{ useMenuUtils().extractNodeName(primiObj, 'carta-category') }}
            </div>
            <div class="menu-items-list">
              <div
                class="menu-item"
                v-for="product in primiObj.products ? primiObj.products : []"
                :key="product.idprodotto"
              >
                <p>
                  <span class="item-name">{{
                    useMenuUtils().extractNodeName(product, 'product')
                  }}</span>
                  <span
                    v-if="
                      useMenuUtils().extractNodeAllergies(
                        product,
                        menuProducts?.prodotti,
                      )
                    "
                    class="item-allergy"
                    >{{
                      useMenuUtils().extractNodeAllergies(
                        product,
                        menuProducts?.prodotti,
                      )
                    }}</span
                  >
                </p>
                <p class="item-price">{{ product.prezzo }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Loading Overlay -->
    <div class="print-menu-overlay">Caricamento PDF in corso...</div>
  </div>
  <!-- PDF visualizzato nel frontend -->
  <div v-if="pdfUrl" class="pdf-object">
    <object :data="pdfUrl" type="application/pdf">
      <p class="pdf-object-disclaimer">
        Il tuo browser non supporta la visualizzazione di PDF.
        <a :href="pdfUrl" class="pdf-object-download" download
          ><span>Scarica il PDF</span></a
        >
      </p>
    </object>
  </div>
</template>

<script setup>
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import { useDatoStore } from '@/store/dato.js'
const { menuCategories, menuTree, menusCustom, menuProducts } = useDatoStore()

definePageMeta({
  layout: 'pdf',
})

// Oggetto generale del menu alla Carta
const actualProductsObj = computed(() => {
  const menuCustom = menusCustom.find((menu) => menu.nome === 'M.carta')
  const parsedMenuCustom = menuCustom
    ? { ...menuCustom, products: menuCustom.prodotti }
    : null
  const categoryNode = parsedMenuCustom
  return categoryNode ? categoryNode : {}
})

const pdfUrl = ref('')
const page1Ref = ref(null)
const page2Ref = ref(null)

// Id dei prodotti da NON considerare nella lista prodotti
const excludedIds = computed(() => '3,4,5,6,7,8,9,10,11,12,13,16')

// Filtro fuori eventuali IDs di categoria da evitare
const parsedChildren = (children) => {
  if (!excludedIds.value?.length) return children
  return children.filter(
    (child) => !excludedIds.value.split(',').includes(child.id?.toString()),
  )
}

// Seguono le computed con i vari elementi del menu da comporre...
const antipastiObj = computed(() => {
  const antipasti = actualProductsObj?.value?.children.find(
    (child) => child.nome === 'M.antipasti',
  )
  return antipasti ? antipasti : null
})

const primiObj = computed(() => {
  const primi = actualProductsObj?.value?.children.find(
    (child) => child.nome === 'M.primi',
  )
  return primi ? primi : null
})

const secondiObj = computed(() => {
  const secondi = actualProductsObj?.value?.children.find(
    (child) => child.nome === 'M.secondi',
  )
  return secondi ? secondi : null
})

const vegObj = computed(() => {
  const veg = actualProductsObj?.value?.children.find(
    (child) => child.nome === 'M.vegetariano',
  )
  return veg ? veg : null
})

const dolciObj = computed(() => {
  const menuCustom = menusCustom.find((menu) => menu.nome === 'M.dolci')
  const parsedMenuCustom = menuCustom
    ? { ...menuCustom, products: menuCustom.prodotti }
    : null
  const categoryNode = parsedMenuCustom
  return categoryNode ? categoryNode : {}
})

const degustaObj = computed(() => {
  const menuCustom = menusCustom.find((menu) => menu.nome === 'M. Degustazione')
  const parsedMenuCustom = menuCustom
    ? { ...menuCustom, products: menuCustom.prodotti }
    : null
  const categoryNode = parsedMenuCustom
  return categoryNode ? categoryNode : {}
})

/* const generatePDF = (html2pdf) => {
  // Usa html2pdf.js per generare il PDF dal contenuto HTML
  const element = pdfContainer.value

  const options = {
    filename: 'documento.pdf',
    jsPDF: { orientation: 'landscape', unit: 'cm', format: 'a3', compress: true },
    html2canvas: {
      scale: 5, // Aumenta la risoluzione (valore predefinito è 1)
      useCORS: true, // Abilita CORS per caricare immagini da domini esterni
      allowTaint: true,
      letterRendering: true // Migliora la qualità del testo
    }
  }

  html2pdf().from(element).set(options).toPdf().get('pdf').then((pdfObj) => {
    pdfUrl.value = pdfObj.output('bloburl')
  })
  // this.mobile = /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
} */

const generatePDF = async () => {
  const pages = [page1Ref.value, page2Ref.value]
  const pdf = new jsPDF({ unit: 'cm', format: 'a3', orientation: 'landscape' })
  let isFirst = true

  for (const el of pages) {
    const rotate = el.dataset.rotate === 'true'

    let canvas = await html2canvas(el, {
      scale: 5,
      useCORS: true,
      allowTaint: true,
      letterRendering: true,
    })

    if (rotate) {
      console.log('===ROTATING===')
      const rotatedCanvas = document.createElement('canvas')
      rotatedCanvas.width = canvas.width
      rotatedCanvas.height = canvas.height
      const ctx = rotatedCanvas.getContext('2d')
      ctx.translate(canvas.width, canvas.height)
      ctx.rotate(Math.PI)
      ctx.drawImage(canvas, 0, 0)
      canvas = rotatedCanvas
    }

    const img = canvas.toDataURL('image/png')
    const w = pdf.internal.pageSize.getWidth()
    const h = pdf.internal.pageSize.getHeight()

    if (!isFirst) pdf.addPage()
    isFirst = false
    pdf.addImage(img, 'PNG', 0, 0, w, h)
  }

  const blob = pdf.output('blob')
  pdfUrl.value = URL.createObjectURL(blob)
}

onMounted(() => {
  setTimeout(generatePDF, 0)
})
</script>

<style lang="scss" scoped>
@page {
  font-family: 'Baskerville', serif;
  size: A3 landscape;
  margin: 0;
}
.pdf-object {
  width: 100%;
  height: 100vh;
}

.pdf-object object {
  width: 100%;
  height: 100%;
  border: none;
}
.pdf-object-disclaimer {
  padding: 16px;
}
.print-menu-container .page {
  font-family: 'Baskerville', serif;
  width: 42cm;
  height: 29.4cm;
  margin: 0 auto;
  padding: 2cm;
  box-sizing: border-box;
  page-break-after: always;
  background-color: #ffffff;
}
.print-menu-container .page:nth-child(even) {
  // background-color: #f0f0f0;
  // transform: rotate(180deg);
}
.menu-section {
  display: flex;
  justify-content: space-between;
  gap: 5.5cm;
  height: 100%;
}
.section-title {
  font-size: 28px;
  color: #7c6210;
  margin-bottom: 32px;
}
.menu-col {
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.menu-item {
  font-size: 20px;
  color: #4b4534;
}
.menu-items-list .menu-item + .menu-item {
  margin-top: 16px;
}
.menu-items-list.--concise .menu-item + .menu-item {
  margin-top: 8px;
}
.item-name.--lighter {
  color: #939393;
}
.item-allergy {
  font-size: 14px;
  color: #939393;
  margin-left: 8px;
}
.item-price {
  color: #239b99;
}
.logos-list {
  display: flex;
  gap: 8px;
}
.logos-list img {
  width: 85px;
  height: auto;
  display: block;
}
.main-logo-container {
  margin-bottom: 60px;
}
.main-logo-container img {
  width: 190px;
  height: auto;
  display: block;
  margin: 0 auto;
}
.logo-separator {
  text-align: center;
  margin: 30px 0;
}
.logo-separator img {
  width: 100px;
  height: auto;
  display: block;
}
.page-break {
  page-break-before: always;
}
.allergy-legenda {
  color: #939393;
  border-top: 1px solid #939393;
  margin-top: 30px;
}
.allergy-legenda-title {
  font-size: 16px;
  padding-top: 6px;
}
.allergy-list-wrap {
  padding-top: 14px;
  column-count: 3;
  column-gap: 0px;
}
.allergy-list {
  font-size: 14px;
}
.print-menu-overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(219, 213, 213);
  color: black;
  border-radius: 20px;
  padding: 50px;
  z-index: 2;
}
</style>
